@font-face {
  font-family: "Font1";
  font-style: none;
  font-weight: 100;
  src: url(fonts/Outfit-VariableFont_wght.ttf) format("truetype");
}
a:link {
  text-decoration: none;
  color: #2e2e2e;
}

a:visited {
  text-decoration: none;
  color: #2e2e2e;
}

a:hover {
  text-decoration: none;
  color: #2e2e2e;
}

a:active {
  text-decoration: none;
  color: #2e2e2e;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  padding: 0%;
  margin: 0%;
}

nav {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: #2e2e2e;
  flex-wrap: wrap;
  position: fixed;
  background-color: #ffffff;
  z-index: 1;
}
nav .main {
  padding-left: 5%;
}
nav div {
  display: flex;
  justify-content: center;
  align-items: center;
}
nav div img {
  width: 50px;
  height: 50px;
  padding: 0%;
}
nav h2 {
  padding-left: 2%;
  font-family: "Font1";
  font-size: 2em;
  color: #2e2e2e;
}
nav ul {
  width: 40%;
  display: flex;
  justify-content: space-evenly;
}
nav ul li {
  list-style: none;
  font-size: 1.3em;
  font-family: "Font1";
  padding: 0 10px 0 10px;
  color: #2e2e2e;
}

#home {
  background-image: url("./img/graph-2588993_1920.jpg");
  width: 100%;
  height: 100vh;
  padding: 0%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
#home div {
  position: absolute;
  left: 5%;
  top: 20%;
}
#home h1 {
  font-size: 5em;
  font-family: "Font1";
  color: #ffffff;
}
#home h3 {
  font-size: 1.5em;
  font-family: "Font1";
  color: #ffffff;
}

#Aboutus {
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
}
#Aboutus h2 {
  margin-top: 5%;
  font-size: 3em;
  font-family: "Font1";
  color: #2e2e2e;
}
#Aboutus div {
  display: flex;
  padding: 0 10% 0 10%;
  align-items: center;
}
#Aboutus div p {
  font-size: 1.5em;
  font-family: "Font1";
  color: #2e2e2e;
}
#Aboutus div img {
  width: 40rem;
  height: 40rem;
  padding-left: 10%;
}

#ourfeatures {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
#ourfeatures h2 {
  margin-top: 5%;
  font-size: 3em;
  font-family: "Font1";
  color: #2e2e2e;
}
#ourfeatures .maina {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
}
#ourfeatures .maina .feature {
  width: 40%;
}
#ourfeatures .maina .feature div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
#ourfeatures .maina h3 {
  margin-top: 5%;
  font-size: 2em;
  font-family: "Font1";
  color: #2e2e2e;
}
#ourfeatures .maina img {
  height: 100px;
  width: auto;
  margin-right: 5%;
}
#ourfeatures .maina p {
  font-size: 1.5em;
  font-family: "Font1";
  color: #2e2e2e;
}

input:focus,
textarea:focus {
  border: none;
  outline: none;
}

#contactus {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  height: 100vh;
}
#contactus h2 {
  margin-top: 5%;
  font-size: 3em;
  font-family: "Font1";
  color: #2e2e2e;
}
#contactus .mainaa {
  display: flex;
  width: 100%;
  height: 80%;
  justify-content: space-evenly;
  align-items: center;
}
#contactus .mainaa form {
  display: flex;
  width: 40%;
  flex-direction: column;
  height: 60vh;
  justify-content: space-evenly;
}
#contactus .mainaa form label {
  font-size: 1.2em;
  color: #2e2e2e;
  font-family: "Font1";
  margin: 20px 0;
}
#contactus .mainaa form input {
  border: none;
  border-bottom: #2e2e2e 1px solid;
  height: 3rem;
  font-size: 1.3em;
  color: #2e2e2e;
  font-family: "Font1";
}
#contactus .mainaa form textarea {
  border: none;
  border-bottom: #2e2e2e 1px solid;
  height: 10rem;
  font-size: 1.5em;
  color: #2e2e2e;
  font-family: "Font1";
}
#contactus .mainaa form button {
  border: none;
  outline: none;
  padding: 15px;
  font-family: "Font1";
  font-size: 1.5em;
  margin: 20px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10%;
}
#contactus .mainaa .social {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30%;
}
#contactus .mainaa .social a {
  margin: 10%;
  font-size: 1.3em;
  color: #2e2e2e;
  font-family: "Font1";
  display: flex;
  align-items: center;
}
#contactus .mainaa .social a img {
  width: 30px;
  height: auto;
  margin-right: 20px;
}

button:hover {
  background-color: #2e2e2e;
  color: #ffffff;
}

footer {
  background-color: #2e2e2e;
  margin: 0%;
  padding: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
footer ul {
  padding: 0%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
footer ul li {
  list-style: none;
  font-size: 1.3em;
  font-family: "Font1";
  color: #ffffff;
}
footer ul li a:link {
  text-decoration: none;
  color: #ffffff;
}
footer ul li a:visited {
  text-decoration: none;
  color: #ffffff;
}
footer ul li a:hover {
  text-decoration: none;
  color: #ffffff;
}
footer ul li a:active {
  text-decoration: none;
  color: #ffffff;
}

@media (max-width: 540px) {
  nav {
    flex-direction: column;
    justify-content: baseline;
    align-items: center;
  }
  nav ul {
    width: 100%;
    padding: 0%;
    justify-content: space-evenly;
    padding-left: 0%;
  }
  nav ul li {
    padding: 0px;
  }
  nav .main {
    padding-left: 0%;
  }
  nav div {
    padding: 0%;
  }
  #home {
    background-size: auto;
  }
  #home div {
    position: absolute;
    display: flex;
    justify-content: center;
    align-self: center;
    flex-direction: column;
  }
  #Aboutus {
    margin-top: 10%;
    height: auto;
  }
  #Aboutus div {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #Aboutus div img {
    width: 90%;
    height: auto;
    padding: 0%;
  }
  #ourfeatures .maina {
    width: 100%;
    justify-content: center;
  }
  #ourfeatures .maina .feature {
    width: 90%;
  }
  #contactus {
    height: auto;
  }
  #contactus .mainaa {
    width: 100%;
    height: auto;
    flex-direction: column;
  }
  #contactus .mainaa form {
    width: 90%;
    margin: 10%;
  }
  #contactus .mainaa .social {
    width: 80%;
  }
  footer {
    padding: 10% 0 10% 0;
  }
  footer ul {
    margin: 0%;
  }
}/*# sourceMappingURL=App.css.map */